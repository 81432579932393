import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Logo from "../../components/logo"
import BuyButton from "../../components/buy"
import Footer from "../../components/footer"
import Bunny from "../../components/images/bunny"
import { useStaticQuery, graphql } from "gatsby"


const Language = "en-EN"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bunnyImage: file(relativePath: { eq: "scotty_easter_bunny.jpg" }) {
        childImageSharp {
          resize(width: 600) {
            src
            height
            width
          }
        }
      }
    }
  `)

  return (

  <Layout>
    <SEO title="Easter chocolates | Vegan | Bunny Wild Carrot" lang={Language} image={data.bunnyImage.childImageSharp.resize} />
    <Logo className="mx-auto h-auto w-56 pt-8 lg:absolute lg:z-10 lg:left-0 lg:ml-12" route="https://candide.berlin/startseite" />
    <div className="px-6 text-center md:flex md:items-end">
      <div className="md:w-1/2">
        <Bunny className=""/>
      </div>
      <div className="text-lg text-gray-800 font-light md:w-1/2 md:text-left md:pr-8">
        <h2 className="mt-8 text-3xl font-bold leading-tight text-gray-800 md:leading-none lg:text-5xl xl:text-6xl">Easter chocolate giftcard</h2>
        <p className="mt-4 text-lg text-gray-800 md:font-thin lg:font-hairline">
          Scotty's a blue bunny that loves carrots 
          and for Easter we created chocolates together.
       For reasons out of our control, we're not launching them now and <span className="font-bold border-canorange border-b-2">offering giftcards instead.*</span></p>
        <div className="mt-4">
          <h3 className="font-bold text-2xl lg:text-3xl">Bunny Wild Carrot</h3>
          <p className="">18 pc vegan Easter chocolates by Scotty the Blue Bunny. <br/>Includes entry to one of our pickup parties.</p>
          <div className="mt-2 font-bold text-5xl leading-none">€ 29,95 </div>
          <div className="w-40 mx-auto md:mx-0 md:flex md:items-end md:justify-start">
            <BuyButton className="" shopifyId="4427965366406" title="ORDER" background="#78b657" color="#ffffff" border="none" />
          </div>
          <p className="mt-6 p-3 shadow-lg mb-12 bg-white border border-gray-100">
              <div className="vimeo">
                <iframe 
                    src="https://player.vimeo.com/video/406731826" 
                    title="Happy Easter"
                    width="640" 
                    height="564" 
                    frameB
                    order="0" allow="autoplay; fullscreen" 
                    allowFullScreen>
                </iframe>
              </div>
          </p>   
        </div>
      </div>
    </div>
    <div className="hidden md:block mt-12 bg-canblue h-16 flex items-center justify-center text-center md:mt-0 lg:justify-end lg:text-right"></div>
    <div className="mt-10 px-6 pt-20 pb-28 text-lg text-gray-800 font-light bg-gray-100 md:mt-0">
      <div className="max-w-xl mx-auto">
        <h3 className="text-3xl font-bold text-gray-800 md:text-5xl">* Dear friends, </h3>
        <p className="mt-4">
          we wanted to produce the Easter chocolates fresh this week.
        </p>
        <p className="mt-4">
          There were plans for a <span className="font-bold">pickup party</span> with the bunny himself at <span className="font-bold">ViniCulture</span> (including a wine pairing). 
        </p>
        <p className="mt-4">
          Obviously, we can't do that now.
        </p>
        <p className="mt-4">
          But we've decided not to cancel the Easter plans, we're going to move them: We'll just <span className="font-bold border-canorange border-b-2">celebrate in… May?</span>
        </p>
        <p className="mt-4">
          Until then, you can reserve your chocolates now with a <span className="font-bold">giftcard</span>. 
        </p>
        <p className="mt-4">
          If you're buying for a friend, submit their name and a greeting by email to kontakt@candide.berlin.
        </p>
        <p className="mt-4">We wish you all a safe and healthy time. <br/><span className="font-bold">Candide &amp; Scotty</span></p>

      </div>
    </div>
      
    <Footer lang={Language} />
  </Layout>
)
}

export default IndexPage
