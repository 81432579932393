import React, { useLayoutEffect } from 'react'
import ShopifyBuy from "@shopify/buy-button-js"

const BuyButton = ( props ) => {
    useLayoutEffect(() => {
        const client = ShopifyBuy.buildClient({
            domain: 'candideberlin.myshopify.com',
            storefrontAccessToken: '6c9a81449887c8481cae3d80dabf5b1a', 

        })
        const ui = ShopifyBuy.UI.init(client)
        ui.createComponent("product", {
            id: props.shopifyId,
            variantId: props.variantId,
            node: document.getElementById(props.shopifyId + '-' + props.variantId),
            moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
            options: {
                "product": {
                    "variantId": props.variantId,
                    "width": "600px",
                    "contents": {
                        "img": false,
                        "imgWithCarousel": false,
                        "title": false,
                        "variantTitle": false,
                        "price": false,
                        "description": false,
                        "buttonWithQuantity": false,
                        "quantity": false,
                        "options": props.showVariantOptions,
                    },
                    "styles": {
                        "product": {
                            "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0",
                            "margin-bottom": "50px"
                            }
                        },
                        "button": {
                            "font-weight": "600",
                            "background": props.background ? props.background : "#ffffff",
                            "color": props.color ? props.color : "#327BB3",
                            "border": props.border ? props.border : "1px solid #327BB3",
                            ":hover" : {
                                "background": "#ffffff",
                                "color" : "#2d355a"
                            }
                        },
                        "variantTitle": {
                            "font-weight": "normal"
                        },
                        "title": {
                            "font-size": "26px"
                        },
                        "description": {
                            "font-weight": "normal"
                        },
                        "price": {
                            "font-size": "18px",
                            "font-weight": "normal"
                        },
                        "compareAt": {
                            "font-size": "15px",
                            "font-family": "Helvetica Neue, sans-serif",
                            "font-weight": "normal"
                        }
                    },
                    "text": {
                        "button": props.title,
                    },
                },
                "cart": {
                    "contents": {
                        "button": true
                    },
                    "popup":false,
                    "styles": {
                        "button": {
                            "font-weight": "normal"
                        },
                        "footer": {
                            "background-color": "#ffffff"
                        }
                    },
                    "text": {
                        "title": props.locale === 'en-US' ? 'Cart' : 'Warenkorb',
                        "empty": props.locale === 'en-US' ? 'Your cart is empty.' : 'Der Warenkorb ist leer.',
                        "button": props.locale === 'en-US' ? 'CHECKOUT' : 'ZUR KASSE',
                        "total": props.locale === 'en-US' ? 'SUBTOTAL' : 'ZWISCHENSUMME',
                        "currency": 'EUR',
                        "notice": props.locale === 'en-US' ? 'Shipping and discount codes are added at checkout.' : 'Versand und Rabatt wird an der Kasse berechnet.',
                        "noteDescription": 'Special instructions for seller',
                    },
                    "events": {
                        "openCheckout": function( cart ) {

                        },
                    }
                },
                "modalProduct": {
                    "contents": {
                    "img": false,
                    "imgWithCarousel": false,
                    "variantTitle": true,
                    "buttonWithQuantity": false,
                    "button": true,
                    "quantity": false
                    },
                    "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                        "max-width": "100%",
                        "margin-left": "0px",
                        "margin-bottom": "0px"
                        }
                    },
                    "button": {
                        "font-weight": "normal"
                    },
                    "variantTitle": {
                        "font-weight": "normal"
                    },
                    "description": {
                        "font-weight": "normal"
                    },
                    "price": {
                        "font-weight": "normal"
                    },
                    "compareAt": {
                        "font-family": "Helvetica Neue, sans-serif",
                        "font-weight": "normal"
                    }
                    }
                },
                "toggle": {
                    "styles": {
                    "toggle": {
                        "font-weight": "normal"
                    }
                    }
                },
                "lineItem": {
                    contents: {
                        image: true,
                        variantTitle: true,
                        title: true,
                        price: false,
                        priceWithDiscounts: true,
                        quantity: true,
                        quantityIncrement: true,
                        quantityDecrement: true,
                        quantityInput: true,
                    },
                },
            }

        })
    })
    return (
        <div className={`${props.className}`} id={props.shopifyId + '-' + props.variantId}></div>
    )
}

export default BuyButton

