import React from "react"
import { Link } from "gatsby"

const Footer = (props) => ( 

    <footer className="px-12 py-6 text-lg bg-gray-800 md:flex md:items-center md:justify-between md:font-thin ">
        <p className="text-white">
            <a href={props.lang === 'de-DE' ? 'https://candide.berlin/startseite' : 'https://candide.berlin/home'}>Candide Schokoladen</a>
        </p>
        <p className="text-white">
            <Link className="" activeClassName="text-gray-400" to="/bunny-wild-carrot">Deutsch</Link>
            <Link className="ml-4" activeClassName="text-gray-400" to="/en/bunny-wild-carrot">English</Link>
        </p>
        <p className="text-white text-gray-400">Copyright &copy;2020</p>
    </footer>

) 

export default Footer